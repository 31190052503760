import React from "react";

import { Form } from "antd";

import { LocaleConsumer, localeCookieName } from '../../providers/LocaleProvider';

import Button from "../../components/Button";
import InputText from "../../components/InputText";
import InputPassword from "../../components/InputPassword";
import Cookie from "../../helpers/Cookie";

import Styles from "./styles.module.scss";
import { ILoginForm } from "./interfaces";
import { ELocale } from "../../providers/LocaleProvider/interfaces";

const LoginForm: React.FC<ILoginForm> = ({onSubmit}) => {
    const [email, setEmail] = React.useState("")

    const defaultLanguage: ELocale = (
        navigator.language || (navigator as any).userLanguage
      ).includes("en")
        ? ELocale.enGB
        : ELocale.frFr;

    const defaultLocale =
    ((Cookie.get(localeCookieName) as ELocale) || null) ?? defaultLanguage;

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Form onFinish={onSubmit} className={Styles["login__form"]} action="">
                    <InputText
                        label={getTranslation("login_page.form.email")}
                        fieldName="email"
                        value={email}
                        setValue={setEmail}
                        rules={[
                            {
                                type: "email",
                                message: getTranslation("order_page.form.invalid_email"),
                                validateTrigger: 'onBlur'
                            },
                            {
                                required: true,
                                message: getTranslation("login_page.form.email_empty_error"),
                            },
                        ]}
                        className={Styles["login__email"]}
                        inputType="email"
                    />
                    <InputPassword
                        label={getTranslation("login_page.form.password")}
                        fieldName="password"
                        rules={[
                            {
                                required: true,
                                message: getTranslation("login_page.form.password_empty_error"),
                            },
                        ]}
                        className={Styles["login__password"]}
                    />
                    <p className={Styles["login__mandatory"]}>{getTranslation("login_page.form.mandatory")}</p>
                    {/* <Button
                        type="submit"
                        styleType="plain"
                        size="large"
                        label={getTranslation("login_page.form.submit")}
                        className={Styles["login__submit"]}
                    /> */}
                    <div className={Styles["login__submit--disabled"]}>
                        {getTranslation("login_page.form.submit")}
                    </div>
                    <span className={Styles["maintenanceText"]}>{defaultLocale === "fr-FR" ? "Application actuellement en maintenance" : "Application currently under maintenance"}</span>
                </Form>
            )}
        </LocaleConsumer>
    )
}

export default LoginForm